@import url(https://fonts.googleapis.com/css?family=Fira+Mono|Nova+Cut);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* Hex to RGBA */
.color__onehtml {
  background: #224762; }

.color__one body {
  background: #224762; }

.color__one .menu-item__title {
  color: #E7D185; }

.color__one a {
  color: #E7D185; }

.color__one .about:hover {
  color: #E7D185; }

.color__one .office__selector:hover .office__title {
  color: #E7D185; }

.color__one .office__selector:hover path {
  stroke: #E7D185; }

.color__one .office-menu__close:hover path {
  stroke: #E7D185; }

.color__twohtml {
  background: #785151; }

.color__two body {
  background: #785151; }

.color__two .menu-item__title {
  color: #85D5E6; }

.color__two a {
  color: #85D5E6; }

.color__two .about:hover {
  color: #85D5E6; }

.color__two .office__selector:hover .office__title {
  color: #85D5E6; }

.color__two .office__selector:hover path {
  stroke: #85D5E6; }

.color__two .office-menu__close:hover path {
  stroke: #85D5E6; }

.color__threehtml {
  background: #655178; }

.color__three body {
  background: #655178; }

.color__three .menu-item__title {
  color: #A1D781; }

.color__three a {
  color: #A1D781; }

.color__three .about:hover {
  color: #A1D781; }

.color__three .office__selector:hover .office__title {
  color: #A1D781; }

.color__three .office__selector:hover path {
  stroke: #A1D781; }

.color__three .office-menu__close:hover path {
  stroke: #A1D781; }

* {
  box-sizing: border-box; }

html,
body {
  width: 100%;
  height: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'Nova Cut', cursive;
  font-weight: normal;
  color: #fff; }

h1 {
  font-size: 56px; }

h2 {
  font-size: 48px; }

h3 {
  font-size: 40px; }

h4 {
  font-size: 32px; }

h5 {
  font-size: 24px; }

h6 {
  font-size: 16px; }

p {
  margin: 0;
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8); }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

.text-center {
  text-align: center; }

.logo {
  display: inline-block;
  position: fixed;
  left: 0;
  top: 10%;
  height: 80vh;
  width: auto;
  z-index: 1;
  opacity: .07; }

.loading {
  display: none;
  font-size: 60px;
  font-family: 'Nova Cut', cursive;
  font-weight: normal;
  text-align: center;
  color: #fff; }

.lunch {
  height: 100%; }
  .lunch__menu {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
  .lunch__day h3 {
    margin-bottom: 0; }
  .lunch__day a {
    display: block; }

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 30px 0 30px; }

.about {
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  border: 0;
  background: none;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }
  .about:active, .about:focus {
    outline: 0; }

/* Office
================================== */
.office__selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 260px;
  margin-bottom: 10px;
  cursor: pointer; }

.office__title {
  margin: 0;
  line-height: 29px;
  color: #fff;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.office path {
  width: 24px;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

/* Menu
================================== */
.office-caterer {
  margin-left: 30px;
  margin-bottom: 60px;
  font-size: 12px; }

.menu-item {
  margin-bottom: 30px;
  padding-left: 40%; }
  .menu-item__inner {
    display: inline-block;
    text-align: left; }
  .menu-item__title {
    margin-bottom: 5px;
    line-height: 58px; }
  .menu-item__food {
    padding-left: 30px; }

@media (max-width: 768px) {
  .menu-item {
    padding-left: 20%; } }

@media (max-width: 480px) {
  .menu-item {
    display: block;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px; } }

/* Page
================================== */
.page {
  margin-top: 108px; }

/* Overlay
================================== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  opacity: 0;
  visibility: hidden; }
  .overlay.show {
    opacity: 1;
    visibility: visible; }

/* Office selection menu
================================== */
.office-menu__links {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.office-menu__title {
  display: block;
  margin: 0;
  padding: 10px 0;
  font-size: 60px;
  font-family: 'Nova Cut', cursive;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.office-menu__close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 5;
  cursor: pointer;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }

.office-menu.office__one {
  background-color: #224762;
  background-color: #224762; }
  .office-menu.office__one .office-menu__title:hover {
    color: #E7D185; }

.office-menu.office__two {
  background-color: #785151;
  background-color: #785151; }
  .office-menu.office__two .office-menu__title:hover {
    color: #85D5E6; }

.office-menu.office__three {
  background-color: #655178;
  background-color: #655178; }
  .office-menu.office__three .office-menu__title:hover {
    color: #A1D781; }

