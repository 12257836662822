@import url('https://fonts.googleapis.com/css?family=Fira+Mono|Nova+Cut');

$blue: #224762;
$gold: #E7D185;
$lightblue: #85D5E6;
$red: #785151;
$green: #A1D781;
$purple: #655178;
$white: #fff;
$offwhite: rgba(255,255,255,.8);

/* Hex to RGBA */
@mixin rgba-background($hexcolor, $opacity) {
  background-color: $hexcolor;
  background-color: rgba($hexcolor, $opacity); 
}

.color {
  &__one {
    &html {
      background: $blue;
    }
    body {
      background: $blue;
    }
    .menu-item__title {
      color: $gold;
    }
    a {
      color: $gold;
    }
    .about {
      &:hover {
        color: $gold;
      }
    }
    .office__selector {
      &:hover {
        .office__title {
          color: $gold;
        }
        path {
          stroke: $gold;
        }
      }
    }
    .office-menu__close {
      &:hover {
        path {
          stroke: $gold;
        }
      }
    }
  }
  &__two {
    &html {
      background: $red;
    }
    body {
      background: $red;
    }
    .menu-item__title {
      color: $lightblue;
    }
    a {
      color: $lightblue;
    }
    .about {
      &:hover {
        color: $lightblue;
      }
    }
    .office__selector {
      &:hover {
        .office__title {
          color: $lightblue;
        }
        path {
          stroke: $lightblue;
        }
      }
    }
    .office-menu__close {
      &:hover {
        path {
          stroke: $lightblue;
        }
      }
    }
  }
  &__three {
    &html {
      background: $purple;
    }
    body {
      background: $purple;
    }
    .menu-item__title {
      color: $green;
    }
    a {
      color: $green;
    }
    .about {
      &:hover {
        color: $green;
      }
    }
    .office__selector {
      &:hover {
        .office__title {
          color: $green;
        }
        path {
          stroke: $green;
        }
      }
    }
    .office-menu__close {
      &:hover {
        path {
          stroke: $green;
        }
      }
    }
  }
}
*{
  box-sizing: border-box;
}

html,
body{
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6{
  margin: 0;
  font-family: 'Nova Cut', cursive;
  font-weight: normal;
  color: #fff;
}
h1 {font-size: 56px;}
h2 {font-size: 48px;}
h3 {font-size: 40px;}
h4 {font-size: 32px;}
h5 {font-size: 24px;}
h6 {font-size: 16px;}

p {
  margin: 0;
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  line-height: 24px;
  color: $offwhite;
}

a {
  text-decoration: none;
  &:hover {
   text-decoration: underline;     
  }
}

.text-center {
  text-align: center;
}

.logo{
  display: inline-block;
  position: fixed;
  left: 0;
  top: 10%;
  height: 80vh;
  width: auto;
  z-index: 1;
  opacity: .07;
}

.loading {
  display: none;
  font-size: 60px;
  font-family: 'Nova Cut', cursive;
  font-weight: normal;
  text-align: center;
  color: #fff;
}

.lunch{
  height: 100%;

  &__menu{
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  &__day{
    
    h3{
      margin-bottom: 0;
    }
    a{
      display: block;
    }
  }
}

header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 30px 0 30px;
}

.about {
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: $offwhite;
  border: 0;
  background: none;
  cursor: pointer;
  transition: all .2s ease-in-out;
  &:active, &:focus {
    outline: 0;
  }
}

/* Office
================================== */
.office{
  &__selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 260px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  &__title {
    margin: 0;
    line-height: 29px;
    color: #fff;
    transition: all .2s ease-in-out;
  }

  path {
    width: 24px;
    transition: all .2s ease-in-out;
  }
}

/* Menu
================================== */
.office-caterer {
  margin-left: 30px;
  margin-bottom: 60px;
  font-size: 12px;
}
.menu-item {
  margin-bottom: 30px;
  padding-left: 40%;
  &__inner {
    display: inline-block;
    text-align: left;
  }
  &__title {
    margin-bottom: 5px;
    line-height: 58px;
  }
  &__food {
    padding-left: 30px;
  }
}
@media (max-width: 768px) {
  .menu-item {
    padding-left: 20%;
  }
}
@media (max-width: 480px) {
  .menu-item {
    display: block;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Page
================================== */
.page {
  margin-top: 108px;
}

/* Overlay
================================== */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  transition: all .2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  &.show {
    opacity: 1;
    visibility: visible;
  }
}

/* Office selection menu
================================== */
.office-menu {
  &__links {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  &__title {
    display: block;
    margin: 0;
    padding: 10px 0;
    font-size: 60px;
    font-family: 'Nova Cut', cursive;
    font-weight: normal;
    color: #fff;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 5;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  &.office__one {
    @include rgba-background($blue, 1);
    .office-menu__title {
      &:hover {
        color: $gold;
      }
    }
  }
  &.office__two {
    @include rgba-background($red, 1);
    .office-menu__title {
      &:hover {
        color: $lightblue;
      }
    }
  }
  &.office__three {
    @include rgba-background($purple, 1);
    .office-menu__title {
      &:hover {
        color: $green;
      }
    }
  }
}